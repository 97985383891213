
// Lead text

p {
    &.u-lead {
        font-size: 1.2em;
        font-weight: 300;
    }
}

h1.u-lead,
h2.u-lead,
h3.u-lead,
h4.u-lead,
h5.u-lead,
h6.u-lead,
.u-leadHeading {
    border-bottom: 1px solid @colour-primary;
}

// Alignment
.u-textLeft           { text-align: left; }
.u-textRight          { text-align: right; }
.u-textCenter         { text-align: center; }
.u-textJustify        { text-align: justify; }
.u-textNoWrap         { white-space: nowrap; }

// Change alignment for smaller screens
@media screen and (max-width: @screen-sm){
    .u-textCenter--sm {
        text-align: center;
    }

    .u-textleft--sm {
        text-align: left;
    }

    .u-textRight--sm {
        text-align: right;
    }
}

@media screen and (max-width: @screen-sm){
    .u-textCenter--md {
        text-align: center;
    }

    .u-textleft--md {
        text-align: left;
    }

    .u-textRight--md {
        text-align: right;
    }
}


/**
 * Enables font kerning in all browsers.
 * http://blog.typekit.com/2014/02/05/kerning-on-the-web/
 *
 * 1. Chrome (not Windows), Firefox, IE 10+
 * 2. Safari 7 and future browsers
 * 3. Chrome (not Windows), Firefox, Safari 6+, iOS, Android
 */
.u-textKern {
  font-feature-settings: "kern" 1; /* 1 */
  font-kerning: normal; /* 2 */
  text-rendering: optimizeLegibility; /* 3 */
}


/**
 * Text truncation
 *
 * Prevent text from wrapping onto multiple lines, and truncate with an
 * ellipsis.
 *
 * 1. Ensure that the node has a maximum width after which truncation can
 *    occur.
 * 2. Fix for IE 8/9 if `word-wrap: break-word` is in effect on ancestor
 *    nodes.
 */
.u-textTruncate {
  max-width: 100%; /* 1 */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  word-wrap: normal !important; /* 2 */
}

// Transforms
.u-textLowerCase      { text-transform: lowercase; }
.u-textUpperCase      { text-transform: uppercase; }
.u-textCapitalize     { text-transform: capitalize; }
