.u-button {
    display: inline-block;
    padding: 0.5em 1.5em;
    background: @colour-secondary;
    // border: 1px solid @colour-neutral;
    color: @colour-positive;
    font-weight: bold;
    text-align: center;
    border-radius: 0 1em 0 1em;
    box-shadow: -4px 4px 0 @colour-secondary-shade;
    transition: all .2s ease-in-out;
    &:focus,
    &:hover {
        color: @colour-positive;
        background: lighten(@colour-secondary, 10%);
        box-shadow: -2px 2px 0 @colour-secondary-shade;
        transform: translate(-2px, 2px);
    }
    &:active {
        color: @colour-positive;
        background: @colour-secondary-shade;
        transform: translate(-4px, 4px);
        box-shadow: 0px 0px 0 @colour-secondary-shade;
    }
}
