
.u-container {
    max-width: @max-width;
    margin-left: auto;
    margin-right: auto;
    padding-left: @space/2;
    padding-right: @space/2;
    @media screen and (max-width: @screen-lg) {
        max-width: @max-width-lg;
    }
}

.u-container--small {
    .u-container();
    max-width: 720px;
}

.u-container--text {
    .u-container();
    max-width: 870px;
}

.u-clear {
    clear:both;
}

// Clear floats like a boss

.u-clearFix {
    &:before,
    &:after {
        content: " "; // 1
        display: table; // 2
    }
    &:after {
        clear: both;
    }
}

.u-block {
    display: block;
}

.u-inlineBlock {
    display: inline-block;
}

.u-inline {
    display: inline;
}

.u-float {
    &--left {
        float: left;
    }
    &--right {
        float: right;
    }
}


// Spacer

// Handy spacer class for adding space between items
.u-spacer(@space: 30px; @space-ratio: 2) {
    display: block;
    height: @space;
    width: @space;
    visibility: hidden;

    &--xs {
        @size: @space / (2 * @space-ratio);
        height: @size;
        width: @size;
    }

    &--sm {
        @size: @space / (1 * @space-ratio);
        height: @size;
        width: @size;
    }

    &--lg {
        @size: @space * (@space-ratio * 1);
        height: @size;
        width: @size;
    }

    &--xl {
        @size: @space * (@space-ratio * 2);
        height: @size;
        width: @size;
    }

    &--xxl {
        @size: @space * (@space-ratio * 3);
        height: @size;
        width: @size;
    }
}

hr.u-spacer {
    border: none;
    margin: 0;
}
