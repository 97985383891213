.typographyColor(@color; @background: #fff) {

    color: @color;

    p {
        color: @color;
    }

    h1,h2,h3,h4,h5,h6 {

        color: @color;

        small {
            color: lighten(@color, 80%);
        }

    }

    a {

        color: @color;

        &:hover {
            color: darken(@color, 10%);
        }

    }

}
