* {
    box-sizing: border-box;
}

button,
input,
select,
textarea {
    display: block;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    outline: none;
    border: none;
    background: none;
    color: inherit
}

html {
    font-size: @font-size;

    @media screen and (min-width: 320px) {
        font-size: calc(~"16px + (18 - 16) * ( (100vw - 320px) / ( 767 - 320) )");
    }

    @media screen and (min-width: @screen-xs-max) {
        font-size: calc(~"16px + (18 - 16) * ( (100vw - 767px) / ( 1920 - 767) )");
    }

    @media screen and (min-width: 1920px) {
        font-size: 18px;
    }
}

body, html {
    margin: 0;
    padding: 0;
    background-color: @colour-background;

    font-family: @font-family;
    color: @font-colour;
    line-height: @line-height;
}

body.public {
    animation: bodyfadein 0.3s;
}

@keyframes bodyfadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

// All images should be responsive by default
img {
    display: block;
	border: none;
	max-width: 100%; // Part 1: Set a maximum relative to the parent
	height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}

html.noscroll {
    overflow: hidden;
}

body.noscroll {
    position: relative;
    width: 100vw;
    overflow: hidden;
}