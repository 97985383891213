
// The content utility is mainly used for areas where a user can add content.
// It provides nice styles for basic content elements like text and images.
// This allows us to have user-friendly styles for certain areas without having them apply to the page as a whole.

.u-content {

    a:active {
        text-decoration: none;
    }

    p {
        margin-bottom: 1em;
        small {
            margin-bottom: 1em;
        }

        a {
            font-size: 1.2em;
            border-bottom: solid 1px transparent;
            &:hover {
                border-bottom: solid 1px @colour-secondary;
            }
        }
    }

    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 20px;
    }

    p, h1, h2, h3, h4, h5, h6 {
        &:last-child {
            margin-bottom: 0;
        }
    }

    img {
        display: inline-block;
    }

}

// We declare modifiers here rather than nested under .u-content so they can be used as mixins.
// (LESS is'nt clever enough to auto-concat the nested name structure when addressing the mixin).

.u-content--positive {
    .typographyColor(#fff);
}

.u-content--negative {
    .typographyColor(#333);
}

.u-content--lg {
    font-size: 1.3em;
}

.u-content--sm {
    font-size: 0.9em;
}

.u-content--xs {
    font-size: 0.8em;
}
