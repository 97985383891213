/*  ====== Theme Variables ======
    Unique to this project
    ========================================================================= */
* {
  box-sizing: border-box;
}
button,
input,
select,
textarea {
  display: block;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  outline: none;
  border: none;
  background: none;
  color: inherit;
}
html {
  font-size: 16px;
}
@media screen and (min-width: 320px) {
  html {
    font-size: calc(16px + (18 - 16) * ( (100vw - 320px) / ( 767 - 320) ));
  }
}
@media screen and (min-width: 767px) {
  html {
    font-size: calc(16px + (18 - 16) * ( (100vw - 767px) / ( 1920 - 767) ));
  }
}
@media screen and (min-width: 1920px) {
  html {
    font-size: 18px;
  }
}
body,
html {
  margin: 0;
  padding: 0;
  background-color: #FFF;
  font-family: 'Quicksand', 'Ariel', sans-serif;
  color: #444;
  line-height: 1.4;
}
body.public {
  animation: bodyfadein 0.3s;
}
@keyframes bodyfadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
img {
  display: block;
  border: none;
  max-width: 100%;
  height: auto;
}
html.noscroll {
  overflow: hidden;
}
body.noscroll {
  position: relative;
  width: 100vw;
  overflow: hidden;
}
form-control {
  margin-top: 10px;
}
input[type=text],
input[type=email],
textarea {
  margin-top: 10px;
  padding: 0.5em 1em;
  border: 1px #3078BB solid;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-radius: 0 1em 0 1em;
  font-family: 'Quicksand', 'Ariel', sans-serif;
  font-size: 1.2em;
  font-weight: 700;
  transition: all 0.2s ease-in-out;
  width: 100%;
}
input[type=text]:focus,
input[type=email]:focus,
textarea:focus {
  border-color: #A6C35C;
  border-left-width: 8px;
  outline: none;
}
button {
  font-family: 'Quicksand', 'Ariel', sans-serif;
  font-size: 1em;
  font-weight: 700;
}
.form-group {
  margin-bottom: 1em;
}
.form-group.submit {
  display: block;
  text-align: right;
}
a {
  transition: color 0.2s ease-in-out, background 0.2s ease-in-out, border 0.2s ease-in-out;
  color: #CF443A;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
p {
  margin: 0;
  padding: 0;
  line-height: 1.4;
}
p strong {
  font-weight: bold;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', 'Ariel', sans-serif;
  color: #5e5e5e;
  line-height: 1.15;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  display: inline-block;
}
h1 small:before,
h2 small:before,
h3 small:before,
h4 small:before,
h5 small:before,
h6 small:before {
  content: ' ';
}
h1 > a,
h2 > a,
h3 > a,
h4 > a,
h5 > a,
h6 > a,
a > h1,
a > h2,
a > h3,
a > h4,
a > h5,
a > h6 {
  color: #CF443A;
  text-decoration: none;
  font-weight: inherit;
}
h1 {
  font-size: 1.728rem;
}
h2 {
  font-size: 1.44rem;
}
h3 {
  font-size: 1.2rem;
}
h4 {
  font-size: 1rem;
}
h5 {
  font-size: 1rem;
}
h6 {
  font-size: 1rem;
}
@media screen and (min-width: 767px) {
  h1 {
    font-size: 2.0736rem;
  }
  h2 {
    font-size: 1.728rem;
  }
  h3 {
    font-size: 1.44rem;
  }
  h4 {
    font-size: 1.2rem;
  }
  h5 {
    font-size: 1rem;
  }
  h6 {
    font-size: 1rem;
  }
}
blockquote {
  margin: 0;
  padding-left: 32px;
  font-family: Georgia, Times, cursive;
  font-style: italic;
  font-size: 32px;
  line-height: 1.4;
  color: #5e5e5e;
  border-left: 1px #E3E0DE solid;
  border-left-width: 4px;
}
ul,
ol {
  margin: 0;
  padding: 0;
}
ul li,
ol li {
  margin-bottom: 22.4px;
  margin-left: 22.4px;
}
hr {
  border: none;
  border-top: 1px #E3E0DE solid;
}
.Box--round {
  border-radius: 4px;
}
.Box--shadow {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}
.u-button {
  display: inline-block;
  padding: 0.5em 1.5em;
  background: #CF443A;
  color: #fff;
  font-weight: bold;
  text-align: center;
  border-radius: 0 1em 0 1em;
  box-shadow: -4px 4px 0 #932b24;
  transition: all 0.2s ease-in-out;
}
.u-button:focus,
.u-button:hover {
  color: #fff;
  background: #d96b63;
  box-shadow: -2px 2px 0 #932b24;
  transform: translate(-2px, 2px);
}
.u-button:active {
  color: #fff;
  background: #932b24;
  transform: translate(-4px, 4px);
  box-shadow: 0px 0px 0 #932b24;
}
.u-content a:active {
  text-decoration: none;
}
.u-content p {
  margin-bottom: 1em;
}
.u-content p small {
  margin-bottom: 1em;
}
.u-content p a {
  font-size: 1.2em;
  border-bottom: solid 1px transparent;
}
.u-content p a:hover {
  border-bottom: solid 1px #CF443A;
}
.u-content h1,
.u-content h2,
.u-content h3,
.u-content h4,
.u-content h5,
.u-content h6 {
  margin-bottom: 20px;
}
.u-content p:last-child,
.u-content h1:last-child,
.u-content h2:last-child,
.u-content h3:last-child,
.u-content h4:last-child,
.u-content h5:last-child,
.u-content h6:last-child {
  margin-bottom: 0;
}
.u-content img {
  display: inline-block;
}
.u-content--positive {
  color: #fff;
}
.u-content--positive p {
  color: #fff;
}
.u-content--positive h1,
.u-content--positive h2,
.u-content--positive h3,
.u-content--positive h4,
.u-content--positive h5,
.u-content--positive h6 {
  color: #fff;
}
.u-content--positive h1 small,
.u-content--positive h2 small,
.u-content--positive h3 small,
.u-content--positive h4 small,
.u-content--positive h5 small,
.u-content--positive h6 small {
  color: #ffffff;
}
.u-content--positive a {
  color: #fff;
}
.u-content--positive a:hover {
  color: #e6e6e6;
}
.u-content--negative {
  color: #333;
}
.u-content--negative p {
  color: #333;
}
.u-content--negative h1,
.u-content--negative h2,
.u-content--negative h3,
.u-content--negative h4,
.u-content--negative h5,
.u-content--negative h6 {
  color: #333;
}
.u-content--negative h1 small,
.u-content--negative h2 small,
.u-content--negative h3 small,
.u-content--negative h4 small,
.u-content--negative h5 small,
.u-content--negative h6 small {
  color: #ffffff;
}
.u-content--negative a {
  color: #333;
}
.u-content--negative a:hover {
  color: #1a1a1a;
}
.u-content--lg {
  font-size: 1.3em;
}
.u-content--sm {
  font-size: 0.9em;
}
.u-content--xs {
  font-size: 0.8em;
}
img.u-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
img.u-left {
  display: block;
  float: left;
  margin-right: 30px;
}
img.u-right {
  display: block;
  float: right;
  margin-left: 30px;
}
.u-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media screen and (max-width: 1440px) {
  .u-container {
    max-width: 1170px;
  }
}
.u-container--small {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 720px;
}
@media screen and (max-width: 1440px) {
  .u-container--small {
    max-width: 1170px;
  }
}
.u-container--text {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 870px;
}
@media screen and (max-width: 1440px) {
  .u-container--text {
    max-width: 1170px;
  }
}
.u-clear {
  clear: both;
}
.u-clearFix:before,
.u-clearFix:after {
  content: " ";
  display: table;
}
.u-clearFix:after {
  clear: both;
}
.u-block {
  display: block;
}
.u-inlineBlock {
  display: inline-block;
}
.u-inline {
  display: inline;
}
.u-float--left {
  float: left;
}
.u-float--right {
  float: right;
}
hr.u-spacer {
  border: none;
  margin: 0;
}
p.u-lead {
  font-size: 1.2em;
  font-weight: 300;
}
h1.u-lead,
h2.u-lead,
h3.u-lead,
h4.u-lead,
h5.u-lead,
h6.u-lead,
.u-leadHeading {
  border-bottom: 1px solid #3078BB;
}
.u-textLeft {
  text-align: left;
}
.u-textRight {
  text-align: right;
}
.u-textCenter {
  text-align: center;
}
.u-textJustify {
  text-align: justify;
}
.u-textNoWrap {
  white-space: nowrap;
}
@media screen and (max-width: 768px) {
  .u-textCenter--sm {
    text-align: center;
  }
  .u-textleft--sm {
    text-align: left;
  }
  .u-textRight--sm {
    text-align: right;
  }
}
@media screen and (max-width: 768px) {
  .u-textCenter--md {
    text-align: center;
  }
  .u-textleft--md {
    text-align: left;
  }
  .u-textRight--md {
    text-align: right;
  }
}
/**
 * Enables font kerning in all browsers.
 * http://blog.typekit.com/2014/02/05/kerning-on-the-web/
 *
 * 1. Chrome (not Windows), Firefox, IE 10+
 * 2. Safari 7 and future browsers
 * 3. Chrome (not Windows), Firefox, Safari 6+, iOS, Android
 */
.u-textKern {
  font-feature-settings: "kern" 1;
  /* 1 */
  font-kerning: normal;
  /* 2 */
  text-rendering: optimizeLegibility;
  /* 3 */
}
/**
 * Text truncation
 *
 * Prevent text from wrapping onto multiple lines, and truncate with an
 * ellipsis.
 *
 * 1. Ensure that the node has a maximum width after which truncation can
 *    occur.
 * 2. Fix for IE 8/9 if `word-wrap: break-word` is in effect on ancestor
 *    nodes.
 */
.u-textTruncate {
  max-width: 100%;
  /* 1 */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  word-wrap: normal !important;
  /* 2 */
}
.u-textLowerCase {
  text-transform: lowercase;
}
.u-textUpperCase {
  text-transform: uppercase;
}
.u-textCapitalize {
  text-transform: capitalize;
}
.u-noJsShow {
  display: none !important;
}
.no-js .u-noJsShow {
  display: block !important;
}
.no-js .u-noJsHide {
  display: none !important;
}
.mce-content-body {
  padding: 15px;
}
.mce-content-body a:active {
  text-decoration: none;
}
.mce-content-body p {
  margin-bottom: 1em;
}
.mce-content-body p small {
  margin-bottom: 1em;
}
.mce-content-body p a {
  font-size: 1.2em;
  border-bottom: solid 1px transparent;
}
.mce-content-body p a:hover {
  border-bottom: solid 1px #CF443A;
}
.mce-content-body h1,
.mce-content-body h2,
.mce-content-body h3,
.mce-content-body h4,
.mce-content-body h5,
.mce-content-body h6 {
  margin-bottom: 20px;
}
.mce-content-body p:last-child,
.mce-content-body h1:last-child,
.mce-content-body h2:last-child,
.mce-content-body h3:last-child,
.mce-content-body h4:last-child,
.mce-content-body h5:last-child,
.mce-content-body h6:last-child {
  margin-bottom: 0;
}
.mce-content-body img {
  display: inline-block;
}
